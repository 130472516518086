footer.ofooter{
    box-sizing: border-box;
    padding: 0 10px;
    .footer__icon{
        width: 40px;
        aspect-ratio: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out .3s;
        p{
            padding: 0;
            margin: 0;
            font-size: 12px;
        }
        &:hover{
            cursor: pointer;
        }
        &:active{
            transform: scale(0.6);
            opacity: 0.5;
        }
    }
    .icon_me{
        border-radius: 0 30px 0 0;
    }
    .icon_qr{
        border-radius: 50%;
    }
    .icon_home{
        border-radius: 30px 0 0 0;
    }
}