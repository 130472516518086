@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');
@import "../../scss/colors.scss";

div.page__visitor{
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 60px;
    background: url("../../../Assets/img/ian-kelsall-MEUvVqkU3QI-unsplash.jpg");
	background-position: center center;
    background-size: cover;
    position: relative;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;

    div.visitor__about{
        width: 100%;
        height: 100%;
        z-index: 200;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all ease-in-out .5s;
        div.about__info{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content:first baseline;
            box-sizing: border-box;
            padding: 10px;
            .info__me{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 300px;
                p{
                    font-family: 'Raleway', sans-serif;
                    color: $fdbck_h;
                    letter-spacing: 1.5px;
                }
                button{
                    font-family: 'Raleway', sans-serif;
                    letter-spacing: 1.5px;
                    background: $bg_log_sbm;
                    color: $icon_input;
                    &:hover{
                        background-color: $updateMe;
                        color: $conf_txt_h;
                    }
                }
                .MuiFormControl-root{
                    label{
                        font-family: 'Raleway', sans-serif;
                        color: $fdbck_h;
                        letter-spacing: 1.5px;
                    }
                    .MuiInputBase-root{
                        font-family: 'Raleway', sans-serif;
                        color: $fdbck_h;
                        letter-spacing: 1.5px;
                        &::before{
                            border-bottom: 1px solid $fdbck_h;
                        }
                        &::after{
                            border-bottom: 1px solid $fdbck_h;
                        }
                        fieldset{
                            border: 1px solid $fdbck_h;
                        }
                        .MuiInputAdornment-root{
                            display: none;
                        }
                    }
                }
                .MuiStack-root{
                    .MuiFormControl-root{
                        label{
                            font-family: 'Raleway', sans-serif;
                            color: $fdbck_h;
                            letter-spacing: 1.5px;
                        }
                        .MuiInputBase-root{
                            font-family: 'Raleway', sans-serif;
                            color: $fdbck_h;
                            letter-spacing: 1.5px;
                            fieldset{
                                border: 1px solid $fdbck_h;
                            }
                            .MuiInputAdornment-root{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        div.about__offers{
            height: calc(100% - 300px);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            overflow-y: scroll;
            box-sizing: border-box;
            padding: 15px 0;
            div.offers__one{
                p{
                    margin: 0;
                    padding: 0;
                    color: $fdbck_h;
                    letter-spacing: 2px;
                    line-height: 30px;
                }
                width: 80%;
                height: fit-content;
                box-sizing: border-box;
                padding: 5px 10px;
                background: $blur_bg;
                backdrop-filter: blur(8px);
            }
            .MuiSlider-root{
                .MuiSlider-rail{
                    color: $fdbck_btn_bord;
                }
                .MuiSlider-track{
                    color: $fdbck_h;
                }
            }
            .MuiSlider-thumb{
                display: none;
            }
        }
        div.myQRcode-page{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .myQRcode{
                width: 300px;
                height: 300px;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
            }
        }
    }

    div.page__bg{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $main_bg;
        z-index: 100;
    }
}