@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

html,
body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

body{
    height: calc(100% - 64px);
    margin-top: 64px;
    font-family: "Raleway", sans-serif;
    overflow: hidden;
    div#root{
        height: 100%;
        nav{
            button{
                margin-right: 0;
            }
        }
        footer{
            width: 100%;
            height: 60px;
            background: $bg_header;
            position: fixed;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 200;
            font-family: 'Montserrat', sans-serif;
            color: $about_txt;
            p{
                padding: 0;
                margin: 0;
            }
            p.rights__bg{
                a{
                    text-decoration: none;
                    color: $about_title;
                }
            }
            .footer__button{
                color: $about_buttons;
            }
        }
    }
    span.info__type_business{
        text-transform: capitalize;
    }
    span.mouseOver{
        &:hover{
            cursor: pointer;
        }
    }
    .MuiPickersPopper-root{
        .MuiPaper-root{
            background: $blur_bg;
            backdrop-filter: blur(28px);
            .MuiPickersLayout-contentWrapper{
                .MuiDateCalendar-root{
                    .MuiPickersCalendarHeader-root{
                        .MuiPickersCalendarHeader-labelContainer{
                            color: $sbm_txt;
                            button{
                                color: $sbm_txt;
                            }
                        }
                        .MuiPickersArrowSwitcher-root{
                            button{
                                color: $sbm_txt;
                            }
                        }
                    }
                    .MuiPickersFadeTransitionGroup-root{
                        .MuiDayCalendar-header{
                            span{
                                color: $sbm_txt;
                            }
                        }
                        .MuiPickersSlideTransition-root{
                            button{
                                color: $sbm_txt;
                            }
                            button.Mui-selected{
                                background-color: $bg_log_sbm;
                            }
                        }
                        .MuiMonthCalendar-root{
                            .MuiPickersMonth-root{
                                button{
                                    color: $sbm_txt;
                                }
                                button.Mui-selected{
                                    background-color: $bg_log_sbm;
                                }
                            }
                        }
                        .MuiYearCalendar-root{
                            .MuiPickersYear-root{
                                button{
                                    color: $sbm_txt;
                                }
                                button.Mui-selected{
                                    background-color: $bg_log_sbm;
                                }
                            }
                        }
                    }
                }
            }
            .MuiDialogActions-root{
                button{
                    color: $fdbck_h;
                    &:hover{
                        background-color: $updateMe;
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    body{
        div#root{
            div.page__owner{
                div.owner__about{
                    display: flex;
                    flex-direction: column;
                    div.features__history,
                    div.features__allUsers,
                    div.features__offers{
                        display: none;
                    }
                    div.alice-carousel{
                        height: 290px;
                        margin-top: 0;
                        margin-bottom: 0px;
                        div{
                            div.alice-carousel__wrapper{
                                ul.alice-carousel__stage{
                                    li.alice-carousel__stage-item{
                                        div.about__info{
                                            p{
                                                font-size: 13px;
                                            }
                                        }
                                        div.about__newOffer{
                                            form{
                                                div.newOffer__form{
                                                    span.form__title{
                                                        font-size: 13px;
                                                    }
                                                    div.MuiFormControl-root{
                                                        height: 40px;
                                                        label.MuiFormLabel-root{
                                                            font-size: 13px;
                                                        }
                                                        input.MuiInputBase-input{
                                                            height: 14px;
                                                        }
                                                    }
                                                    .errorMessage{
                                                        height: 5px;
                                                    }
                                                }
                                                button{
                                                    padding: 3px 14px;
                                                    font-size: 0.775rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    div.about__features{
                        display: block;
                        height: calc(100% - 305px);
                        div.alice-carousel{
                            height: 100%;
                            div{
                                div.alice-carousel__wrapper{
                                    ul{
                                        li{
                                            div.features__history,
                                            div.features__allUsers,
                                            div.features__offers{
                                                display: block;
                                            }
                                            div.features__allUsers{
                                                p.allUsers__user{
                                                    height: 50px;
                                                    &:not(:first-child) {
                                                        margin-top: 8px;
                                                    }
                                                }
                                            }
                                            div.features__offers,
                                            div.features__history{
                                                div.offers__card{
                                                    padding: 7px;
                                                    height: 112px;
                                                    p{
                                                        font-size: 13px;
                                                        height: 22px;
                                                    }
                                                    button{
                                                        padding: 3px 13px;
                                                    }
                                                    &:not(:first-child) {
                                                        margin-top: 8px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .MuiDialogContent-root{
            .MuiPickersLayout-root{
                .MuiPickersToolbar-root{
                    .MuiTypography-root{
                        color: $sbm_txt;
                    }
                }
                .MuiPickersLayout-contentWrapper{
                    .MuiDateCalendar-root{
                        .MuiPickersCalendarHeader-root{
                            .MuiPickersCalendarHeader-labelContainer{
                                color: $sbm_txt;
                                button{
                                    color: $sbm_txt;
                                }
                            }
                            .MuiPickersArrowSwitcher-root{
                                button{
                                    color: $sbm_txt;
                                }
                            }
                        }
                        .MuiPickersFadeTransitionGroup-root{
                            .MuiDayCalendar-header{
                                span{
                                    color: $sbm_txt;
                                }
                            }
                            .MuiPickersSlideTransition-root{
                                button{
                                    color: $sbm_txt;
                                }
                                button.Mui-selected{
                                    background-color: $bg_log_sbm;
                                }
                            }
                            .MuiMonthCalendar-root{
                                .MuiPickersMonth-root{
                                    button{
                                        color: $sbm_txt;
                                    }
                                    button.Mui-selected{
                                        background-color: $bg_log_sbm;
                                    }
                                }
                            }
                            .MuiYearCalendar-root{
                                .MuiPickersYear-root{
                                    button{
                                        color: $sbm_txt;
                                    }
                                    button.Mui-selected{
                                        background-color: $bg_log_sbm;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
    nav.MuiPaper-root{
        height: 60px;
    }
    body{
        height: calc(100% - 56px);
        margin-top: 56px;
        div#root{
            height: 100%;
            div.home{
                div.home__about{
                    width: 80%;
                }
            }
            footer{
                button{
                    display: none;
                }
                div.footer__rights,
                p{
                    font-size: 12px;
                    padding: 0 8px;
                }
            }
            div.signup{
                form{
                    .screen{
                        width: 360px;
                    }
                    .loginSignUp{
                        padding: 10px 30px;
                        width: 320px;
                        grid-template-columns: repeat(1, 1fr);
                        grid-template-rows: repeat(8, 1fr);
                        gap: 10px;
                        grid-template-areas: 
                        "username"
                        "business_type"
                        "business_name"
                        "address"
                        "phone"
                        "taxId"
                        "password"
                        "login__submit";
                    }
                }
            }
            div.page__visitor{
                div.visitor__about{
                    div.about__info{
                        height: 200px;
                    }
                    div.about__offers{
                        height: calc(100% - 200px);
                    }
                }
            }
            div.NFPage,
            div.personalPage__bonus{
                height: 110%;
                margin-top: -56px;
            }
        }
        div.MuiModal-root{
            div.modalFeedback{
                width: 90% !important;
            }
            div.MuiBox-root{
                width: 100%;
            }
        }
        div.MuiAlert-action{
            margin-right: 2px;
        }
    }
}