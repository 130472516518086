@import "../../scss/colors.scss";

div.MuiDialog-container{
    div.MuiPaper-root{
        background: none;
        h2{
            background: $bg_header;
            color: $conf_txt_h;
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            padding: 5px 25px;
            font-size: 1.2rem;
        }
        div.MuiDialogContent-root{
            background: $blur_bg;
            backdrop-filter: blur(28px);
            padding: 0;
            box-sizing: border-box;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                color: $sbm_txt;
                background: none;
            }
        }
        div.MuiDialogActions-root{
            background: $bg_header;
            button{
                color: $conf_btn;
                font-weight: 600;
            }
        }
    }
}