@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');
@import "../../../scss/colors.scss";

div.signup{
	background: url("../../../../Assets/img/ian-kelsall-MEUvVqkU3QI-unsplash.jpg");
	background-position: center center;
    background-size: cover;
    position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;	
		font-family: Raleway, sans-serif;
	}

	div.signin__bg{
		position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $main_bg;
        z-index: 100;
	}

	form {
		z-index: 200;
		.container {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 100vh;
		}
	
		.screen {	
			position: relative;	
			max-height: 600px;
			width: 360px;
			overflow-y: scroll;
			background: $blur_bg;
			backdrop-filter: blur(6px);
		}

		.screenOwner{
			width: 790px;
		}
	
		.screen__content {
			z-index: 1;
			position: relative;
			width: 100%;
			height: 100%;
		}

		.loginSignUp {
			width: 320px;
			padding: 30px;
			padding-top: 0px;
		}

		.ownerGrid{
			width: 750px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(4, 1fr);
			gap: 30px;
			.username{
				grid-area: username;
			}
			.business_type{
				grid-area: business_type;
			}
			.business_name{
				grid-area: business_name;
			}
			.address{
				grid-area: address;
			}
			.phone{
				grid-area: phone;
			}
			.taxId{
				grid-area: taxId;
			}
			.password{
				grid-area: password;
			}
			.login__submit{
				grid-area: login__submit;
			}
			grid-template-areas: 
			"username phone"
			"business_type address"
			"business_name password"
			"taxId login__submit";
		}
	
		.login__field {
			padding: 20px 0px;	
			position: relative;	
		}
	
		.login__icon {
			position: absolute;
			top: 31.5px;
			color: $icon_input;
		}

		.icon__eye{
			right: 0px;
			&:hover{
				cursor: pointer;
			}
		}
	
		.login__input {
			border: none;
			border-bottom: 2px solid $icon_input;
			background: none;
			padding: 10px;
			padding-left: 24px;
			font-weight: 700;
			font-size: 17px;
			width: 100%;
			transition: .2s;
			color: $icon_input;
			&:-webkit-autofill{
				transition: background-color 5000s;
  				-webkit-text-fill-color: $icon_input !important;
				&:hover{
					transition: background-color 5000s;
  					-webkit-text-fill-color: $icon_input !important;
				}
				&:focus{
					transition: background-color 5000s;
  					-webkit-text-fill-color: $icon_input !important;
				}
				&:active{
					transition: background-color 5000s;
  					-webkit-text-fill-color: $icon_input !important;
				}
			}
		}
	
		.login__input:active,
		.login__input:focus,
		.login__input:hover {
			outline: none;
			border-bottom-color: $clear_wht;
		}
	
		.login__submit {
			background: $bg_log_sbm;
			font-size: 14px;
			margin-top: 30px;
			padding: 16px 20px;
			border-radius: 26px;
			border: 1px solid $sbm_border;
			text-transform: uppercase;
			font-weight: 700;
			display: flex;
			align-items: center;
			width: 100%;
			color: $sbm_txt;
			box-shadow: 0px 2px 2px $sbm_shadow;
			cursor: pointer;
			transition: .2s;
		}
	
		.login__submit:active,
		.login__submit:focus,
		.login__submit:hover {
			border-color: $clear_wht;
			outline: none;
		}
	
		.button__icon {
			font-size: 24px;
			margin-left: auto;
			color: $sbm_txt;
		}
	
		.social-login {	
			position: absolute;
			height: 140px;
			width: 160px;
			text-align: center;
			bottom: 0px;
			right: 0px;
			color: $clear_wht;
		}
	
		.social-icons {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	
		.social-login__icon {
			padding: 20px 10px;
			color: $clear_wht;
			text-decoration: none;	
			text-shadow: 0px 0px 8px $icon_txt_shadow;
		}
	
		.social-login__icon:hover {
			transform: scale(1.5);	
		}
	
		.errorMessage {
			height: 15px;
			font-size: 12px;
			color: $err_txt;
			display: block;
			font-weight: 700;
		}
	}
}