@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');
@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
@import "../../scss/colors.scss";

div.page__owner{
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 60px;
    background: url("../../../Assets/img/ian-kelsall-MEUvVqkU3QI-unsplash.jpg");
	background-position: center center;
    background-size: cover;
    position: relative;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    div.owner__about{
        z-index: 200;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        div.about__info{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content:first baseline;
            box-sizing: border-box;
            padding: 10px;
            .info__me{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 300px;
                p{
                    font-family: 'Raleway', sans-serif;
                    color: $fdbck_h;
                    letter-spacing: 1.5px;
                }
                button{
                    font-family: 'Raleway', sans-serif;
                    letter-spacing: 1.5px;
                    background: $bg_log_sbm;
                    color: $icon_input;
                    &:hover{
                        background-color: $updateMe;
                        color: $conf_txt_h;
                    }
                }
                .MuiFormControl-root{
                    label{
                        font-family: 'Raleway', sans-serif;
                        color: $fdbck_h;
                        letter-spacing: 1.5px;
                    }
                    .MuiInputBase-root{
                        font-family: 'Raleway', sans-serif;
                        color: $fdbck_h;
                        letter-spacing: 1.5px;
                        &::before{
                            border-bottom: 1px solid $fdbck_h;
                        }
                        &::after{
                            border-bottom: 1px solid $fdbck_h;
                        }
                        fieldset{
                            border: 1px solid $fdbck_h;
                        }
                        .MuiInputAdornment-root{
                            display: none;
                        }
                    }
                }
                .MuiStack-root{
                    .MuiFormControl-root{
                        label{
                            font-family: 'Raleway', sans-serif;
                            color: $fdbck_h;
                            letter-spacing: 1.5px;
                        }
                        .MuiInputBase-root{
                            font-family: 'Raleway', sans-serif;
                            color: $fdbck_h;
                            letter-spacing: 1.5px;
                            fieldset{
                                border: 1px solid $fdbck_h;
                            }
                            .MuiInputAdornment-root{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        div.about__newOffer{
            form{
                display: flex;
                flex-direction: column;
                div.newOffer__form{
                    box-sizing: border-box;
                    padding: 10px 0;
                    span.form__title{
                        color: $fdbck_h;
                        line-height: 30px;
                    }
                    div.MuiFormControl-root{
                        label.MuiFormLabel-root{
                            color: $sbm_txt
                        }
                        label.Mui-focused{
                            color: $fdbck_h;
                        }
                        div.MuiInputBase-root{
                            &::before{
                                border-bottom: 1px solid $sbm_txt
                            }
                            input.MuiInputBase-input{
                                color: $sbm_txt;
                                &:-webkit-autofill{
                                    transition: background-color 5000s;
                                      -webkit-text-fill-color: $icon_input !important;
                                    &:hover{
                                        transition: background-color 5000s;
                                          -webkit-text-fill-color: $icon_input !important;
                                    }
                                    &:focus{
                                        transition: background-color 5000s;
                                          -webkit-text-fill-color: $icon_input !important;
                                    }
                                    &:active{
                                        transition: background-color 5000s;
                                          -webkit-text-fill-color: $icon_input !important;
                                    }
                                }
                            }
                            &::after{
                                border-bottom: 1px solid $fdbck_h;
                            }
                        }
                    }
                    .errorMessage {
                        height: 15px;
                        font-size: 12px;
                        color: $err_txt;
                        display: block;
                        font-weight: 700;
                    }
                }
                button{
                    border: 1px solid $fdbck_btn_bord;
                    color: $fdbck_h;
                    &:hover{
                        background-color: $fdbck_btn_hov_bg;
                        border: 1px solid $fdbck_h;
                    }
                }
            }
        }
        div.features__allUsers{
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            width: 1fr;
            overflow-y: scroll;
            gap: 10px;
            p{
                font-family: 'Barlow', sans-serif;
                color: $fdbck_h;
                line-height: 30px;
                letter-spacing: 1.5px;
                padding: 0;
                margin: 0;
                width: 100%;
            }
            p.allUsers__user{
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $blur_bg;
                backdrop-filter: blur(8px);
                border-radius: 10px;
            }
        }
        div.features__history,
        div.features__offers{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            height: 100%;
            width: 100%;
            overflow-y: scroll;
            p{
                font-family: 'Barlow', sans-serif;
                color: $fdbck_h;
                line-height: 30px;
                letter-spacing: 1.5px;
                padding: 0;
                margin: 0;
                width: 100%;
            }
            div.offers__card{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                background: $blur_bg;
                backdrop-filter: blur(8px);
                border-radius: 10px;
                box-sizing: border-box;
                padding: 8px;
                height: fit-content;
                p{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        div.about__features{
            display: none;
            width: 100%;
            height: calc(100% - 360px);
            box-sizing: border-box;
            div.alice-carousel{
                width: 100%;
                background: transparent;
                backdrop-filter: none;
                height: 100%;
                div{
                    width: 100%;
                    height: 100%;
                    div.alice-carousel__wrapper{
                        width: 100%;
                        height: 100%;
                        ul.alice-carousel__stage{
                            width: 100%;
                            height: 100%;
                            li.alice-carousel__stage-item{
                                width: 100%;
                                height: 100%;
                                div.features__allUsers{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    height: 100%;
                                    overflow-y: scroll;
                                    gap: 10px;
                                    p{
                                        font-family: 'Barlow', sans-serif;
                                        color: $fdbck_h;
                                        line-height: 30px;
                                        letter-spacing: 1.5px;
                                        padding: 0;
                                        margin: 0;
                                        width: 100%;
                                    }
                                    p.allUsers__user{
                                        height: 60px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background: $blur_bg;
                                        backdrop-filter: blur(8px);
                                        border-radius: 10px;
                                    }
                                }
                                div.features__offers{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    gap: 10px;
                                    height: 100%;
                                    overflow-y: scroll;
                                    p{
                                        font-family: 'Barlow', sans-serif;
                                        color: $fdbck_h;
                                        line-height: 30px;
                                        letter-spacing: 1.5px;
                                        padding: 0;
                                        margin: 0;
                                        width: 100%;
                                    }
                                    div.offers__card{
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-end;
                                        justify-content: center;
                                        background: $blur_bg;
                                        backdrop-filter: blur(8px);
                                        border-radius: 10px;
                                        box-sizing: border-box;
                                        padding: 8px;
                                        height: fit-content;
                                        p{
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ul.alice-carousel__dots{
                    left: 45%;
                }
            }
        }
        .offers__table{
            height: 400px;
            flex: 1;
            div{
                .MuiDataGrid-root{
                    background: $fdbck_bg;
                    backdrop-filter: blur(8px);
                    border-color: $bg_header;
                    border-width: 2px;
                    font-family: 'Raleway', sans-serif;
                    color: $fdbck_h;
                    letter-spacing: 1.5px;
                    .MuiDataGrid-columnSeparator{
                        color: $bg_header;
                    }
                    .MuiDataGrid-withBorderColor{
                        border-color: $bg_header;
                        border-width: 2px;
                    }
                    .MuiDataGrid-menuIconButton{
                        svg{
                            color: $fdbck_h;
                        }
                    }
                    .MuiDataGrid-iconButtonContainer{
                        button{
                            svg{
                                color: $fdbck_h;
                            }
                        }
                    }
                    .MuiDataGrid-footerContainer{
                        font-family: 'Raleway', sans-serif;
                        color: $fdbck_h;
                        letter-spacing: 1.5px;
                        .MuiTablePagination-toolbar{
                            font-family: 'Raleway', sans-serif;
                            color: $fdbck_h;
                            letter-spacing: 1.5px;
                            p{
                                font-family: 'Raleway', sans-serif;
                                color: $fdbck_h;
                                letter-spacing: 1.5px;
                            }
                            svg{
                                color: $fdbck_h;
                            }
                        }
                    }
                    .MuiDataGrid-cell:focus,
                    .MuiDataGrid-columnHeader:focus{
                        outline: solid $bg_header 1px;
                    }
                }
            }
        }
    }

    div.page__bg{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $main_bg;
        z-index: 100;
    }
}
.MuiDialog-root{
    .MuiBackdrop-root{
        background-color: $muibckdr_bg;
    }
}
.MuiDataGrid-menu,
.MuiDataGrid-panel{
    .MuiPaper-root{
        background: $fdbck_bg;
        backdrop-filter: blur(8px);
        font-family: 'Raleway', sans-serif;
        color: $fdbck_h;
        letter-spacing: 1.5px;
        svg{
            color: $fdbck_h;
        }
        select,
        label{
            font-family: 'Raleway', sans-serif;
            color: $fdbck_h;
            letter-spacing: 1.5px;
            .MuiSwitch-switchBase{
                color: $bg_header;
            }
            .Mui-checked{
                color: $fdbck_h;
            }
            .Mui-checked+.MuiSwitch-track {
                background-color: $fdbck_h;
            }
        }
        .MuiInputBase-root{
            &::after{
                border-bottom: 2px solid $fdbck_h;
            }
            input{
                font-family: 'Raleway', sans-serif;
                color: $fdbck_h;
                letter-spacing: 1.5px;
            }
        }
    }
}
.MuiDataGrid-panelFooter{
    .MuiButtonBase-root{
        font-family: 'Raleway', sans-serif;
        color: $fdbck_h;
        letter-spacing: 1.5px;
        &:hover{
            background-color: $updateMe;
        }
    }
}