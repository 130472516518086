$bg_header: #4C0013;
$home_bg: rgb(57, 50, 50);
$bghome__about: #36000eb4;
$about_txt: rgb(190, 190, 190);
$about_title: rgb(255, 132, 0);
$about_buttons: #005cb9;
$main_bg: rgba(0, 0, 33, 0.654);
$blur_bg: rgba(0, 0, 0, 0.02);
$icon_input: #ffffff99;
$clear_wht: #fff;
$bg_log_sbm: #410514cf;
$sbm_txt: #aeaeae;
$err_txt: rgb(191, 0, 0);
$sbm_border: #d4d3e89a;
$sbm_shadow: #535353;
$icon_txt_shadow: #7875B5;
$clear_red: rgb(255, 0, 0);
$conf_txt_h: rgba(255, 132, 0, 0.849);
$updateMe: rgba(255, 132, 0, 0.079);
$conf_btn: #a23c3c;
$fdbck_bg: rgba(255, 255, 255, 0.01);
$fdbck_h: rgb(255, 132, 0);
$fdbck_btn_bord: rgba(255, 132, 0, 0.436);
$fdbck_btn_hov_bg: rgba(210, 102, 25, 0.04);
$bonus_btn_bor: rgba(155, 155, 155, 0.5);
$bonus_btn_txt: #e5e5e5;
$bonus_btn_bg_hov: rgba(25, 118, 210, 0.04);
$inact_dot: rgba(255, 255, 255, 0.476);
$muibckdr_bg: rgba(0, 0, 0, 0.1);